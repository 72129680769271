import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare’s Security Whitepaper',
  header: 'Uploadcare’s Security Whitepaper',
  description: 'Uploadcare is committed to complying with industry-standard privacy and security measures and all applicable laws and regulations to keep customer and end-user data safe and secure.'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`For purposes of keeping customer and end-user data ("Customer Data") safe and
secure, Uploadcare is committed to complying with industry-standard privacy and
security measures, as well as with all applicable data privacy and security laws
and regulations. This includes ensuring that Uploadcare's systems and
infrastructure are protected against unauthorized or accidental access, loss,
alteration, disclosure, or destruction. Uploadcare has taken all necessary
technical and operational measures to organize and protect its facilities,
hardware, and software, personnel, storage and networks, access controls,
monitoring and logging, vulnerability and breach detection, and incident
response measures.`}</p>
    <h2 {...{
      "id": "access-control"
    }}><a parentName="h2" {...{
        "href": "#access-control"
      }}>{`Access Control`}</a></h2>
    <h3 {...{
      "id": "preventing-unauthorized-product-access"
    }}><a parentName="h3" {...{
        "href": "#preventing-unauthorized-product-access"
      }}>{`Preventing Unauthorized Product Access`}</a></h3>
    <p><strong parentName="p">{`Authentication.`}</strong>{` A uniform password policy has been implemented for our
customer products (minimum of 8 symbols length, password cannot be similar to
login or only numbers), and we provide multi-factor authentication. Customers
who interact with Uploadcare Services via the user interface must authenticate
before they can access non-public customer data.`}</p>
    <p><strong parentName="p">{`Authorization.`}</strong>{` Customer Data is stored in multi-tenant storage systems
accessible to Customers only via the user interface and application programming
interfaces. Customers are not allowed for direct access to the underlying
application infrastructure. The authorization model in each of our products is
designed to ensure that only the properly assigned entities can access relevant
features. Public product APIs may be accessed using Public and Secret API keys.`}</p>
    <p><strong parentName="p">{`Separation of environments.`}</strong>{` We separate development, testing, and
operational environments to minimize the risks of unauthorized access or changes
to the operational environment.`}</p>
    <p><strong parentName="p">{`Employee access.`}</strong>{` A limited number of our trained employees have access to
the products and to customer data via controlled interfaces. The purpose of
enabling employee access is to provide efficient customer support, detect and
respond to security incidents, troubleshoot potential problems, and facilitate
data security. Employees are granted access by role, and all such access
requests are logged. Only a few designated employees have access to the
infrastructure. 2FA and SSO are used and enforced on services and resources that
support them. Uploadcare employees do not have physical access to customers'
databases. All employees and contractors have to sign confidentiality or
non-disclosure agreements. All employees receive privacy and security training
during their onboarding process. Access to critical and sensitive data is
role-based (on a "need to know" basis), only for purposes of performing
services' functions, asset owners shall review users' access rights at regular
intervals, access should be revoked periodically, and must be revoked
immediately for terminated employees.`}</p>
    <h3 {...{
      "id": "preventing-unauthorized-infrastructure-access"
    }}><a parentName="h3" {...{
        "href": "#preventing-unauthorized-infrastructure-access"
      }}>{`Preventing Unauthorized Infrastructure Access`}</a></h3>
    <p><strong parentName="p">{`Physical and environmental security.`}</strong>{` Our product infrastructure is hosted
with multi-tenant, outsourced infrastructure providers. Their physical and
environmental security controls are audited for SOC 2 Type II and ISO 27001
compliance, among other certifications.`}</p>
    <p><strong parentName="p">{`Third-party processing.`}</strong>{` In order for us to provide our customers with the
Service in accordance with our DPA, we maintain contractual relationships with
vendors. This includes contractual agreements, privacy policies, and vendor
compliance programs. All our vendors are vetted for privacy and security
compliance during and after their engagement with us.`}</p>
    <p><strong parentName="p">{`Network security.`}</strong>{` Network access control mechanisms are designed to prevent
network traffic using unauthorized protocols from reaching the product
infrastructure. The implemented technical measures differ between infrastructure
providers and include Virtual Private Cloud (VPC) implementations, security
group assignment, and traditional firewall rules. We have implemented a Web
Application Firewall (WAF) solution to protect internet-accessible applications.
The WAF is designed to identify and prevent attacks against publicly available
network services.`}</p>
    <p><strong parentName="p">{`Penetration testing.`}</strong>{` We are launching annual penetration testing with
industry-recognized service provider starting from 2021. The purpose of
penetration testing is to identify and resolve foreseeable attack vectors and
potential abuse scenarios. Penetration report will be available to our customers
upon request with a prior signed NDA.`}</p>
    <p><strong parentName="p">{`Bug bounty.`}</strong>{` A bug bounty program welcomes and incentivizes independent
security researchers to identify and uncover security flaws ethically. We
implement a bug bounty program to widen the available opportunities to engage
with the security community and enhance the product defenses against
sophisticated attacks.`}</p>
    <h2 {...{
      "id": "transmission-control"
    }}><a parentName="h2" {...{
        "href": "#transmission-control"
      }}>{`Transmission Control`}</a></h2>
    <p><strong parentName="p">{`Data is encrypted while in transfer.`}</strong>{` We use the latest encryption protocols
(e.g., TLS 1.2+) and disable obsolete and vulnerable ones. User interfaces are
accessible only with HTTPS. Our customers can enforce HTTPS for all their
transfers.`}</p>
    <p><strong parentName="p">{`Server-side encryption at rest`}</strong>{`. We use Amazon S3-Managed Keys
(SSE-S3), each object is encrypted with a unique key. As an additional
safeguard, it encrypts the key itself with a root key that it regularly
rotates. Amazon S3 server-side encryption uses one of the strongest block
ciphers available, 256-bit Advanced Encryption Standard (AES-256), to encrypt
your data.`}</p>
    <p><strong parentName="p">{`Password data encryption.`}</strong>{` It's encrypted with PBKDF2, HMAC, and SHA256
algorithms.`}</p>
    <h2 {...{
      "id": "input-control"
    }}><a parentName="h2" {...{
        "href": "#input-control"
      }}>{`Input Control`}</a></h2>
    <p><strong parentName="p">{`Detection.`}</strong>{` We designed our infrastructure to log extensive information about
the system behavior, traffic received, system authentication, and other
application requests. Internal systems aggregate log data and alert appropriate
employees of malicious, unintended, or anomalous activities. Our staff,
including security, operations, and support personnel, are responsive to known
incidents.`}</p>
    <p><strong parentName="p">{`Response and tracking.`}</strong>{` We maintain a record of known security incidents that
includes descriptions, dates and times of relevant activities, and incident
disposition. Suspected and confirmed security incidents are investigated by
security, operations, or support personnel, and appropriate resolution steps are
identified and documented. For any confirmed incidents, we will take appropriate
steps to minimize product and Customer damage or unauthorized disclosure. We
will notify our customers in accordance with the Terms of Service.`}</p>
    <h2 {...{
      "id": "availability-control"
    }}><a parentName="h2" {...{
        "href": "#availability-control"
      }}>{`Availability Control`}</a></h2>
    <p><strong parentName="p">{`Infrastructure availability.`}</strong>{` The infrastructure providers use commercially
reasonable efforts to ensure a minimum of 99.9% uptime. The providers maintain a
minimum of N+1 redundancy to power, network, and HVAC services. We process
customer data separately from other data in a multi-tenant environment. We're
hosted on a multi-tenant logically-separated AWS cloud infrastructure. No single
customer can affect capacity, which is designed with embedded rate limits and
throttling.`}</p>
    <p><strong parentName="p">{`Fault tolerance.`}</strong>{` Backup and replication strategies are designed to ensure
redundancy and fail-over protections during a significant processing failure.
Customer data is backed up to multiple durable data stores and replicated across
multiple availability zones. Rollbacks are tested regularly.`}</p>
    <p><strong parentName="p">{`Redundancy and seamless fail-over.`}</strong>{` The server instances that support the
products are architected with a goal to prevent single points of failure. This
design assists our operations in maintaining and updating the product
applications and backend while limiting downtime.`}</p>
    <p><strong parentName="p">{`Business Continuity.`}</strong>{` Uploadcare shall maintain policies and procedures to
ensure that Uploadcare may continue to perform business-critical functions in
the face of an extraordinary event. This includes data center resiliency and
disaster recovery procedures for business-critical data and processing
functions.`}</p>
    <p><strong parentName="p">{`Development best practices.`}</strong>{` We're using git for version control of both
private and public repositories. Any merge to the main branch requires a
sign-off of at least two engineers and green light from CI. Changes to the code
are tested via CI. This includes both static code analysis  and the running
unit, functional and integration test suites against artifacts. We're using
"configuration as code approach" as much as it's feasible. We're monitoring
vulnerability databases and assessing new vulnerabilities to determine if they
apply to our systems/vendors.`}</p>
    <h2 {...{
      "id": "compliance--certification"
    }}><a parentName="h2" {...{
        "href": "#compliance--certification"
      }}>{`Compliance & Certification`}</a></h2>
    <p>{`Following the GDPR and CCPA, Uploadcare undertakes to take all appropriate
precautions to preserve the privacy and the security of the data and, in
particular, to protect them against any accidental or unlawful destruction,
accidental loss, corruption, unauthorized circulation or access, as well as
against any other form of unlawful processing or disclosure to unauthorized
persons. Learn more about this in our `}<a parentName="p" {...{
        "href": "/about/trust/"
      }}>{`Trust Center`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      